<template>
  <VueHtml2pdf
    :show-layout="false"
    :float-layout="true"
    :enable-download="false"
    :preview-modal="false"
    :paginate-elements-by-height="1400"
    filename="myPDF"
    :pdf-quality="4"
    :manual-pagination="false"
    pdf-format="a7"
    pdf-orientation="portrait"
    pdf-content-width="270px"
    ref="html2Pdf"
    @beforeDownload="beforeDownload"
    :html-to-pdf-options="Html2PdfOptions"
  >
    <!-- <VueHtml2pdf :show-layout="false" :float-layout="false" :enable-download="false" :preview-modal="false" :paginate-elements-by-height="1400" filename="myPDF" :pdf-quality="4" :manual-pagination="false" pdf-format="a7" pdf-orientation="portrait" pdf-content-width="270px" ref="html2Pdf" @beforeDownload="beforeDownload" :html-to-pdf-options="Html2PdfOptions"> -->
    <section slot="pdf-content">
      <div class="outer-div">
        <div class="inner-div" style="margin-top: 20px">
          <!-- <v-img crossorigin="*" src="@/assets/logomarket.png" max-height="60" contain/> -->
          <img src="/image_express.png" height="60" />
          <div>YASSIR Express</div>
          <p>Commande&nbsp;#{{ order.tracking_number_ext }}</p>
          <div
            style="
              font-size: 8px;
              text-align: center;
              padding: 0;
              margin-bottom: 10px;
            "
          >
            {{ order.created_at }}
          </div>
        </div>
      </div>
      <hr />

      <table
        v-if="order.products"
        style="
          font-size: 9px;
          justify-content: center;
          width: 100%;
          word-wrap: break-word;
        "
      >
        <thead>
          <tr style="text-align: center">
            <td style="width: 60%; text-align: left">Designation</td>
            <td style="width: 20%">Prix</td>
            <td style="width: 10%">Qte</td>
            <td style="width: 10%">Totale</td>
          </tr>
        </thead>

        <tbody style="border-top: 1px solid #e5e5e5 !important">
          <tr
            style="text-align: center"
            v-for="product in order.products"
            :key="product.id"
          >
            <td style="text-align: left">{{ product.name }}</td>
            <td>{{ product.price }}</td>
            <td>{{ product.qte_in_order }}</td>
            <td>
              {{ product.price * product.qte_in_order }}
            </td>
          </tr>
        </tbody>
      </table>
      <hr style="border-top: 1px solid #e5e5e5 !important" />

      <table style="width: 100%; margin-top: 10px">
        <tbody style="">
          <tr style="vertical-align: top">
            <td align="left"></td>
            <td align="right"></td>
          </tr>
          <tr style="vertical-align: top; font-size: 9px; font-weight: none">
            <td style="border-top-color: rgb(232, 232, 232)" align="left">
              TTC
            </td>
            <td style="border-top-color: rgb(232, 232, 232)" align="right">
              <div style="width: 200px">{{ order.ttc }}&nbsp;DA</div>
            </td>
          </tr>
          <tr style="vertical-align: top; font-size: 9px; font-weight: none">
            <td style="border-top-color: rgb(232, 232, 232)" align="left">
              Reduction
            </td>
            <td style="border-top-color: rgb(232, 232, 232)" align="right">
              <div style="width: 200px">{{ order.reduction }}&nbsp;DA</div>
            </td>
          </tr>
          <tr style="vertical-align: top; font-size: 9px; font-weight: none">
            <td style="border-top-color: rgb(232, 232, 232)" align="left">
              Timber
            </td>
            <td style="border-top-color: rgb(232, 232, 232)" align="right">
              <div style="width: 200px">{{ order.stamp_amount }}DA</div>
            </td>
          </tr>
          <tr style="vertical-align: top; font-size: 12px; font-weight: 600">
            <td style="border-top-color: rgb(232, 232, 232)" align="left">
              TOTAL
            </td>
            <td style="border-top-color: rgb(232, 232, 232)" align="right">
              <div style="width: 200px">{{ order.total }}&nbsp;DA</div>
            </td>
          </tr>
          <tr
            v-if="order.amount_received"
            style="vertical-align: top; font-size: 9px; font-weight: none"
          >
            <td style="border-top-color: rgb(232, 232, 232)" align="left">
              Montant reçu
            </td>
            <td style="border-top-color: rgb(232, 232, 232)" align="right">
              <div style="width: 200px">{{ order.amount_received }}DA</div>
            </td>
          </tr>
          <tr
            v-if="order.amount_received"
            style="vertical-align: top; font-size: 9px; font-weight: none"
          >
            <td style="border-top-color: rgb(232, 232, 232)" align="left">
              Montant retourné
            </td>
            <td style="border-top-color: rgb(232, 232, 232)" align="right">
              <div style="width: 200px">
                {{ order.amount_received - order.total }}DA
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <hr />
      <div style="font-size: 10px; line-height: 9pt; justify-content: center">
        Le retour des produits sera accepté sous 48H sur présentation du ticket,
        il fera l'objet d'un avoir et nom d'un remboursement
      </div>

      <hr />
    </section>
  </VueHtml2pdf>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: {
    VueHtml2pdf,
  },

  computed: {
    Html2PdfOptions: function () {
      return {
        enableLinks: false,
        filename: "myPDF.pdf",
        html2canvas: { scale: 4, useCORS: true },
        image: { type: "png", quality: 0.98 },
        // jsPDF: {unit: 'mm', format: [209.76, 230 + 200 * this.order?.products?.length], orientation: 'portrait'},
        jsPDF: {
          unit: "mm",
          format: [74, parseInt(100 + 5 * this.order?.products?.length)],
          orientation: "portrait",
        },
      };
    },
  },

  props: {
    order: {
      types: Object,
    },
  },

  methods: {
    generateReport() {
      console.log("generateReport");
      this.$refs.html2Pdf.generatePdf();
    },

    async beforeDownload({ html2pdf, options, pdfContent }) {
      console.log("generateReport");
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .outputPdf()
        .get("pdf")
        .then((pdfObj) => {
          const element = document.getElementById("invoice_iframe");
          element?.remove();
          var iframe = document.createElement("iframe"); //load content in an iframe to print later
          document.body.appendChild(iframe);

          iframe.style.display = "none";
          iframe.id = "invoice_iframe";
          iframe.src = pdfObj.output("bloburl");
          iframe.onload = function () {
            setTimeout(function () {
              iframe.focus();
              iframe.contentWindow.print();
            }, 100);
          };

          const anchor = document.createElement("a");
          anchor.href = pdfObj.output("bloburl");
          anchor.download =
            "invoice_item_" + this.order.tracking_number_ext + ".pdf";
          anchor.target = "_blank";
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        });
    },
  },

  data() {
    return {};
  },
};
</script>

<style>
.inner-div {
  text-align: center;
  margin: 0 auto;
  /* width: 100px; */
}
</style>
